import React, { useState, useEffect } from 'react';
import * as helpers from '../../helpers/config';
import ProductService from '../ProductService/index';
import { useDispatch, useSelector } from 'react-redux';
import { onCartAction } from '../../actions/cart/addtocart.action';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { BsArrowRightCircleFill, BsInfoCircle } from 'react-icons/bs';
import LeftArrow from '../CustomArrow/LeftArrow';
import RightArrow from '../CustomArrow/RightArrow';
import SorryCart from '../SorryCart/index';
import { Link } from 'react-router-dom';
import ViewMore from '../ViewMore';
import { RevertLoginStatus } from '../../actions/login.action';
import { BsSearch, BsFilter } from 'react-icons/bs';
import Decrement from '../CommonIcon/Decrement';
import Increment from '../CommonIcon/Increment';
import CustomizeService from '../CustomizeService';
import dic from 'i18next';
import { LazyLoadImage } from 'react-lazy-load-image-component';

function RegularPastries(props) {
  const dispatch = useDispatch();
  const productshow = props?.fields?.Pastries;
  const [serviceUpdate, setServiceUpdate] = useState(false);
  const [customizeModal, setCustomizeModal] = useState(false);
  const [productAddons, setproductAddons] = useState(false);
  const [serviceModal, setServiceModal] = useState(false);
  const [getSorryStatus, setSorryModal] = useState(false);
  const [getViewMore, setViewMore] = useState(false);
  const [getDescription, SetDescription] = useState(false);
  const [customizeType, setCustomizeType] = useState('');
  const cartstatus = useSelector((state) => state.usercart.user_cart_status);
  const { existcartdata, existcartstatus } = useSelector((state) => state.cartlist);

  const sessionproducts = localStorage.getItem('cartproducts');
  const get_value = JSON.parse(sessionproducts);
  const [cartItems, setCartItems] = useState(get_value || []);
  var logoutstatus = useSelector((state) => state.login.logoutstatus);

  const title = props?.productitems?.name;
  const ServingValue = props?.fields?.Servings?.value;
  const pastryName = props && props?.fields?.['Pastry Names'];
  const [cakeList, setCakeList] = useState(productshow);
  const [tempList, setTempList] = useState(productshow);
  const searchHandler = (e) => {
    var lowerCase = e.target.value.toLowerCase();
    if (e.target.value === '') {
      return setTempList(cakeList);
    }
    var filteredData = cakeList.filter((el) => {
      return el.fields['Pastry Name'].fields?.Title?.value.toLowerCase().includes(lowerCase);
    });
    setTempList(filteredData);
  };
  const filterHandler = (e) => {
    document.getElementById('searchholder').value = '';
    var filtervalue = e.target.value;
    if (filtervalue === 'hightolow') {
      const Updateprice = cakeList.filter((items, key) => {
        return items.fields.Services.sort((a, b) =>
          a.fields.Price.value > b.fields.Price.value ? 1 : -1
        );
      });
      const updateHightoLow = Updateprice.sort((a, b) => {
        return a.fields.Services[0].fields.Price.value > b.fields.Services[0].fields.Price.value
          ? -1
          : 1;
      });
      setTempList(updateHightoLow);
    } else if (filtervalue === 'lowtohigh') {
      const Updateprice = cakeList.filter((items, key) => {
        return items.fields.Services.sort((a, b) =>
          a.fields.Price.value > b.fields.Price.value ? 1 : -1
        );
      });
      const updateLowtoHigh = Updateprice.sort((a, b) => {
        return a.fields.Services[0].fields.Price.value > b.fields.Services[0].fields.Price.value
          ? 1
          : -1;
      });
      setTempList(updateLowtoHigh);
    } else {
      setTempList(cakeList);
    }
  };
  const modalUpdateStatus = (items, addon, servicetype) => {
    setCustomizeType(servicetype);
    if (servicetype === 'customize') {
      setServiceModal(true);
      setCustomizeModal(false);
    } else if (servicetype === 'signature') {
      setServiceModal(false);
      setCustomizeModal(true);
    } else if (servicetype === 'macaroons') {
      setServiceModal(false);
      setCustomizeModal(true);
    }
    setServiceUpdate(items);
    setproductAddons(addon);
  };
  const closeModal = () => {
    setServiceModal(!serviceModal);
  };
  const closeModalCustomize = () => {
    setCustomizeModal(false);
  };
  const SorryCartStatusUpdate = () => {
    setSorryModal(!getSorryStatus);
  };
  const ViewMoreUpdate = (
    description,
    images,
    cakename,
    items,
    addons,
    type,
    exists,
    counts,
    findlowestprice
  ) => {
    const detail = {
      description: description,
      images: images,
      cakename: cakename,
      items: items,
      addons: addons,
      type: type,
      exists: exists,
      counts: counts,
      findlowestprice: findlowestprice,
    };
    SetDescription(detail);
    setViewMore(!getViewMore);
  };
 const onCartFunction = (
    items,
    serviceid,
    message,
    price,
    serves,
    addons,
    selectStatus,
    action,
    type
  ) => {
    if (!serviceid) return false;

    let ProductType = '';
    if (type === 'customize') {
      setServiceModal(false);
      ProductType = 'REGULAR';
    } else if (type === 'signature') {
      setCustomizeModal(false);
      setServiceModal(false);
      ProductType = 'SIGNATUREBOX';
    } else if (type === 'macaroons') {
      setCustomizeModal(false);
      setServiceModal(false);
      ProductType = 'MACAROONBOX';
    }

    setViewMore(false);

    const defaultValue = 1;

    const itemImage = items?.fields['Pastry Images'][0]?.fields?.Image?.value?.src;
    const itemTitle = items?.fields['Pastry Name']?.fields?.Title?.value;

    const ItemDetails = {
      id: items?.id,
      image: itemImage,
      title: itemTitle,
    };

    const areArraysEqual = (arr1, arr2) => {
      if (arr1.length !== arr2.length) return false;
      const sorted1 = [...arr1].sort();
      const sorted2 = [...arr2].sort();
      return JSON.stringify(sorted1) === JSON.stringify(sorted2);
    };

    // Check if the item exists in the cart
    const existingItem = cartItems.find(
      (currentcart) => currentcart?.ItemDetails?.id === items?.id
    );

    if (existingItem) {
      // Check if a combination of service details and flavors (from macaroonBox or signatureBox) exists
      const exactMatch = existingItem.cartaddedservice.find((cartItem) => {
        const isSameFlavor =
          (ProductType === 'MACAROONBOX' && areArraysEqual(cartItem.macaroonBox, selectStatus)) ||
          (ProductType === 'SIGNATUREBOX' && areArraysEqual(cartItem.signatureBox, selectStatus));

        return (
          cartItem.id === serviceid &&
          cartItem.message.value === message &&
          cartItem.price.value === price &&
          cartItem.addon === addons &&
          cartItem.type === ProductType &&
          isSameFlavor
        );
      });

      if (exactMatch) {
        // If exact match found (same flavors), update quantity
        if (exactMatch.quantity.value < 50) {
          exactMatch.quantity.value += defaultValue;
          setCartItems([...cartItems]);
          dispatch(onCartAction(cartItems));
        } else {
          addToast('Maximum quantity allowed is 50', { appearance: 'error' });
        }
      } else {
        // If no exact match or flavors differ, add as a new service entry
        existingItem.cartaddedservice.push({
          id: serviceid,
          quantity: { value: defaultValue },
          message: { value: message },
          price: { value: price },
          serves: { value: serves },
          addon: addons,
          type: ProductType,
          signatureBox: type === 'signature' ? selectStatus : [],
          macaroonBox: type === 'macaroons' ? selectStatus : [],
        });
        setCartItems([...cartItems]);
        dispatch(onCartAction(cartItems));
      }
    } else {
      // If item doesn't exist in the cart, add it as a new item
      const newItem = {
        ItemDetails: ItemDetails,
        cartaddedservice: [
          {
            id: serviceid,
            quantity: { value: defaultValue },
            message: { value: message },
            price: { value: price },
            serves: { value: serves },
            addon: addons,
            type: ProductType,
            signatureBox: type === 'signature' ? selectStatus : [],
            macaroonBox: type === 'macaroons' ? selectStatus : [],
          },
        ],
      };

      const updatedCart = [...cartItems, newItem];
      setCartItems(updatedCart);
      dispatch(onCartAction(updatedCart));
    }
  };
  useEffect(() => {
    if (existcartstatus === true) {
      setCartItems(existcartdata);
    }
  }, [existcartdata]);
  useEffect(() => {
    localStorage.setItem('cartproducts', JSON.stringify(cartItems));
    if (logoutstatus === true) {
      dispatch(RevertLoginStatus());
      localStorage.setItem('cartproducts', JSON.stringify([]));
      setCartItems([]);
    }
  });
  const geturl = window.location.pathname;
  var customize_status = '';
  if (geturl.includes('signature-box')) {
    customize_status = 'signature';
  } else if (geturl.includes('macaroons')) {
    customize_status = 'macaroons';
  } else {
    customize_status = 'customize';
  }
  return (
    <div>
      <div className="bg-black text-white px-10 lg:pt-10 -z-10">
        <div className="container-fluid sm:px-6 md:m-0">
          <div className="grid  md:grid-cols-1 sm:grid-cols-1 text-center  px-6 py-10 lg:py-0">
            <div className="py-4 relative hidden">
              <BsSearch
                size={14}
                border-radius={5}
                className="inline absolute top-[29px] left-[11px]"
              />
              <input
                type="text"
                id="searchholder"
                onChange={searchHandler}
                className="bg-black border-2 border-white-300 pl-8  placeholder:text-white tracking-wide text-sm rounded-lg block w-full md:w-64 lg:w-96 outline-none	 p-2.5 "
                placeholder={dic.t('Margaux-Search')}
                required
              />
            </div>
            {/* <div className="text-right py-4 relative">
              <div className="desk-view">
                <BsFilter
                  size={19}
                  className="inline absolute left-[9px] sm:right-[13rem] sm:left-[unset]  md:right-[14rem] lg:right-[22rem] top-[26px]"
                />
              </div>
              <select
                onChange={filterHandler}
                className="bg-black border-2 ml-auto border-white-300 pl-8 placeholder:text-white tracking-wide text-sm rounded-lg block w-full md:w-64 lg:w-96 outline-none	 p-2.5"
              >
                <option value={'reset'}>{dic.t('Margaux-Select a Filter')}</option>
                <option value="hightolow">{dic.t('Margaux-High to Low')}</option>
                <option value="lowtohigh">{dic.t('Margaux-Low to High')}</option>
              </select>
            </div> */}
          </div>
          <div
            className={`${
              tempList && tempList.length > 0
                ? 'grid  2xl:grid-cols-3 xl:grid-cols-3 lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-1'
                : 'grid  grid-cols-1'
            }`}
          >
            {tempList && tempList.length > 0 ? (
              tempList.map((items, key) => {
                const exists = cartItems.filter((currentcart) => {
                  return currentcart?.ItemDetails?.id === items?.id;
                });
                var counts = 0;
                exists.map((itemcheck) => {
                  itemcheck.cartaddedservice.map((serviceitem) => {
                    return (counts = counts + serviceitem?.quantity?.value);
                  });
                });
                const pricelist = items.fields.Services;
                const checkCustomize =
                  items?.fields?.Services[0]?.fields['Service To Persons']?.fields?.Code?.value;
                const findlowestprice = Math.min(
                  ...pricelist.map((item) => item.fields.Price.value)
                );
                const description = items?.fields?.Description?.fields?.Description?.value;
                const descriptionplain = description.slice(0, 170) + '...';
                const images = items?.fields['Pastry Images'][0]?.fields?.Image?.value?.src;
                const allimages = items?.fields?.['Pastry Images'];
                const addons = items?.fields?.Addons;
                const cakename = items?.fields['Pastry Name']?.fields?.Title?.value;
                return (
                  <div className="flex justify-center md:px-6 py-8 px-0" key={key}>
                    <div className="flex justify-center overflow-hidden">
                      <div className="max-w-full bg-white rounded-lg shadow-md">
                        <img
                          className="w-screen object-cover h-60 hover:scale-125 ease-in duration-500 xl:h-64 2xl:h-72 rounded-t-lg"
                          src={images}
                          alt="product image"
                        />
                        <div className="px-5 py-4 border-t-2 border-gray-500 relative z-[9] bg-white	">
                          <h5 className="sm:text-xl text-lg font-semibold tracking-widest text-gray-900">
                            {cakename}
                          </h5>
                          <p className="text-gray-700 text-[0.68rem] py-2">
                            <div
                              style={{ display: 'inline' }}
                              dangerouslySetInnerHTML={{
                                __html: descriptionplain,
                              }}
                            />
                            <span
                              onClick={() =>
                                ViewMoreUpdate(
                                  description,
                                  allimages,
                                  cakename,
                                  items,
                                  addons,
                                  parseInt(checkCustomize) === 1 ? 'customize' : customize_status,
                                  exists,
                                  counts,
                                  findlowestprice
                                )
                              }
                              className="text-gray-700 font-bold cursor-pointer underline underline-offset-1 hover:text-sky-800"
                            >
                              See More
                            </span>
                          </p>
                          <div className="flex justify-between">
                            <div className="relative font-bold text-gray-900 pr-2 py-2 sm:pr-2 lg:pr-5 sm:py-2.5">
                              {helpers.currency} {findlowestprice}
                            </div>
                            <div className="relative font-bold text-gray-900 py-2 sm:py-2.5">
                              {exists &&
                              exists[0] &&
                              exists[0].cartaddedservice &&
                              exists[0].cartaddedservice.length > 0 ? (
                                <div className="grid grid-cols-3 addtocartbox bg-gray-700 px-1 py-1 rounded-md">
                                  <div
                                    className="flex relative top-1	cursor-pointer"
                                    onClick={SorryCartStatusUpdate}
                                  >
                                    <Decrement />
                                  </div>
                                  <div className="flex text-white px-1 justify-center text-lg">
                                    {counts}
                                  </div>
                                  <div
                                    className="flex relative top-[0.2em] cursor-pointer"
                                    onClick={() =>
                                      modalUpdateStatus(
                                        items,
                                        addons,
                                        parseInt(checkCustomize) === 1
                                          ? 'customize'
                                          : customize_status
                                      )
                                    }
                                  >
                                    <Increment />
                                  </div>
                                </div>
                              ) : (
                                <div>
                                  <Link
                                    onClick={() =>
                                      modalUpdateStatus(
                                        items,
                                        addons,
                                        parseInt(checkCustomize) === 1
                                          ? 'customize'
                                          : customize_status
                                      )
                                    }
                                    className="text-white font-normal hover:bg-gray-700 bg-gray-600 uppercase rounded-lg text-xs ml-2 sm:ml-0 lg:text-sm px-2 py-2 sm:px-2 lg:px-5  sm:pt-2.5 text-center"
                                  >
                                    {dic.t('Margaux-Add To Cart')}
                                  </Link>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })
            ) : (
              <div className="grid grid-cols-1  border-white-500 py-20 px-16">
                <p className="text-xl font-bold text-white py-5 m-auto sm:pl-3">
                  {dic.t('Margaux-Product Not Found')}
                </p>
                <div className="icons m-auto">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6 mb-6 text-white"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth="2"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z"
                    />
                  </svg>
                </div>
              </div>
            )}
            {/* Product Service Start */}
            {serviceModal === true && (
              <>
                <ProductService
                  modalStatus={serviceModal}
                  modalUpdateStatus={modalUpdateStatus}
                  productAddons={productAddons}
                  servicelist={serviceUpdate}
                  onCartFunction={onCartFunction}
                  closeModal={closeModal}
                  type={ServingValue}
                />
              </>
            )}
            {/* Customize Service End */}
            {customizeModal === true && (
              <>
                <CustomizeService
                  pastryName={pastryName}
                  customizeType={customizeType}
                  closeModalCustomize={closeModalCustomize}
                  modalStatus={customizeModal}
                  modalUpdateStatus={modalUpdateStatus}
                  productAddons={productAddons}
                  servicelist={serviceUpdate}
                  onCartFunction={onCartFunction}
                  closeModal={closeModal}
                  type={ServingValue}
                />
              </>
            )}
            {/* Product Service End */}
            {/* Sorry Cart Start */}
            {getSorryStatus === true && (
              <SorryCart
                SorryCartStatusUpdate={SorryCartStatusUpdate}
                checkModal={getSorryStatus}
              />
            )}
            {/* Sorry Cart End */}
            {/* View More Start */}
            {getViewMore === true && (
              <ViewMore
                getDescription={getDescription}
                ViewMoreUpdate={ViewMoreUpdate}
                getViewMore={getViewMore}
                modalUpdateStatus={modalUpdateStatus}
              />
            )}
            {/* View More End */}

            <div className="py-5"></div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RegularPastries;
