/* eslint-disable react/jsx-no-undef */
/* eslint-disable react/jsx-key */
import React, { useState, useEffect } from 'react';
import * as helpers from '../../helpers/config';
import ProductService from '../ProductService/index';
import { useDispatch, useSelector } from 'react-redux';
import { onCartAction } from '../../actions/cart/addtocart.action';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { BsArrowRightCircleFill, BsInfoCircle } from 'react-icons/bs';
import LeftArrow from '../CustomArrow/LeftArrow';
import RightArrow from '../CustomArrow/RightArrow';
import SorryCart from '../SorryCart/index';
import { Link } from 'react-router-dom';
import ViewMore from '../ViewMore';
import { RevertLoginStatus } from '../../actions/login.action';
import Decrement from '../CommonIcon/Decrement';
import Increment from '../CommonIcon/Increment';
import dic from 'i18next';
import SignatureBox from '../SignatureBox';
import { useToasts } from 'react-toast-notifications';
import CustomizeService from '../CustomizeService';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import RightArrowNew from '../CustomArrow/RightArrowNew';
import LeftArrowNew from '../CustomArrow/LeftArrowNew';
import {
  festivalHamperSettings,
  topRecommendSettings,
  topTrendingSettings,
} from '../../helpers/helper';

function TopRecommendedCakes(props) {
  var dispatch = useDispatch();
  const { addToast } = useToasts();
  const {
    toprecommend,
    toptrending,
    signatureBox,
    pastryName,
    Description,
    servings,
    FestivalHampers,
  } = props;
  const [serviceUpdate, setServiceUpdate] = useState(false);
  const [customizeModal, setCustomizeModal] = useState(false);
  const [productAddons, setproductAddons] = useState(false);
  const [serviceModal, setServiceModal] = useState(false);
  const [getSorryStatus, setSorryModal] = useState(false);
  const [getViewMore, setViewMore] = useState(false);
  const [getDescription, SetDescription] = useState(false);
  const [customizeType, setCustomizeType] = useState('');
  const cartstatus = useSelector((state) => state.usercart.user_cart_status);
  const { existcartdata, existcartstatus } = useSelector((state) => state.cartlist);

  const sessionproducts = localStorage.getItem('cartproducts');
  const get_value = JSON.parse(sessionproducts);
  var [cartItems, setCartItems] = useState(get_value || []);
  var logoutstatus = useSelector((state) => state.login.logoutstatus);

  const title = props?.toprecommend?.name;
  const modalUpdateStatus = (items, addon, servicetype) => {
    setCustomizeType(servicetype);
    if (servicetype === 'customize') {
      setServiceModal(true);
      setCustomizeModal(false);
    } else if (servicetype === 'signature') {
      setServiceModal(false);
      setCustomizeModal(true);
    } else if (servicetype === 'macaroons') {
      setServiceModal(false);
      setCustomizeModal(true);
    }
    setServiceUpdate(items);
    setproductAddons(addon);
  };
  const closeModal = () => {
    setServiceModal(!serviceModal);
  };
  const closeModalCustomize = () => {
    setCustomizeModal(false);
  };
  const SorryCartStatusUpdate = () => {
    setSorryModal(!getSorryStatus);
  };
  const ViewMoreUpdate = (
    description,
    images,
    cakename,
    items,
    addons,
    type,
    exists,
    counts,
    findlowestprice
  ) => {
    const detail = {
      description: description,
      images: images,
      cakename: cakename,
      items: items,
      addons: addons,
      type: type,
      exists: exists,
      counts: counts,
      findlowestprice: findlowestprice,
    };
    SetDescription(detail);
    setViewMore(!getViewMore);
  };
  const onCartFunction = (
    items,
    serviceid,
    message,
    price,
    serves,
    addons,
    selectStatus,
    action,
    type
  ) => {
    if (!serviceid) return false;

    let ProductType = '';
    if (type === 'customize') {
      setServiceModal(false);
      ProductType = 'REGULAR';
    } else if (type === 'signature') {
      setCustomizeModal(false);
      setServiceModal(false);
      ProductType = 'SIGNATUREBOX';
    } else if (type === 'macaroons') {
      setCustomizeModal(false);
      setServiceModal(false);
      ProductType = 'MACAROONBOX';
    }

    setViewMore(false);

    const defaultValue = 1;

    const itemImage = items?.fields['Pastry Images'][0]?.fields?.Image?.value?.src;
    const itemTitle = items?.fields['Pastry Name']?.fields?.Title?.value;

    const ItemDetails = {
      id: items?.id,
      image: itemImage,
      title: itemTitle,
    };

    const areArraysEqual = (arr1, arr2) => {
      if (arr1.length !== arr2.length) return false;
      const sorted1 = [...arr1].sort();
      const sorted2 = [...arr2].sort();
      return JSON.stringify(sorted1) === JSON.stringify(sorted2);
    };

    // Check if the item exists in the cart
    const existingItem = cartItems.find(
      (currentcart) => currentcart?.ItemDetails?.id === items?.id
    );

    if (existingItem) {
      // Check if a combination of service details and flavors (from macaroonBox or signatureBox) exists
      const exactMatch = existingItem.cartaddedservice.find((cartItem) => {
        const isSameFlavor =
          (ProductType === 'MACAROONBOX' && areArraysEqual(cartItem.macaroonBox, selectStatus)) ||
          (ProductType === 'SIGNATUREBOX' && areArraysEqual(cartItem.signatureBox, selectStatus));

        return (
          cartItem.id === serviceid &&
          cartItem.message.value === message &&
          cartItem.price.value === price &&
          cartItem.addon === addons &&
          cartItem.type === ProductType &&
          isSameFlavor
        );
      });

      if (exactMatch) {
        // If exact match found (same flavors), update quantity
        if (exactMatch.quantity.value < 50) {
          exactMatch.quantity.value += defaultValue;
          setCartItems([...cartItems]);
          dispatch(onCartAction(cartItems));
        } else {
          addToast('Maximum quantity allowed is 50', { appearance: 'error' });
        }
      } else {
        // If no exact match or flavors differ, add as a new service entry
        existingItem.cartaddedservice.push({
          id: serviceid,
          quantity: { value: defaultValue },
          message: { value: message },
          price: { value: price },
          serves: { value: serves },
          addon: addons,
          type: ProductType,
          signatureBox: type === 'signature' ? selectStatus : [],
          macaroonBox: type === 'macaroons' ? selectStatus : [],
        });
        setCartItems([...cartItems]);
        dispatch(onCartAction(cartItems));
      }
    } else {
      // If item doesn't exist in the cart, add it as a new item
      const newItem = {
        ItemDetails: ItemDetails,
        cartaddedservice: [
          {
            id: serviceid,
            quantity: { value: defaultValue },
            message: { value: message },
            price: { value: price },
            serves: { value: serves },
            addon: addons,
            type: ProductType,
            signatureBox: type === 'signature' ? selectStatus : [],
            macaroonBox: type === 'macaroons' ? selectStatus : [],
          },
        ],
      };

      const updatedCart = [...cartItems, newItem];
      setCartItems(updatedCart);
      dispatch(onCartAction(updatedCart));
    }
  };

  useEffect(() => {
    if (existcartstatus === true) {
      setCartItems(existcartdata);
    }
  }, [existcartdata]);
  useEffect(() => {
    localStorage.setItem('cartproducts', JSON.stringify(cartItems));
    if (logoutstatus === true) {
      dispatch(RevertLoginStatus());
      localStorage.setItem('cartproducts', JSON.stringify([]));
      setCartItems([]);
    }
  });
  const geturl = window.location.pathname;
  var customize_status = '';
  if (geturl.includes('signature-box')) {
    customize_status = 'signature';
  } else if (geturl.includes('macaroons')) {
    customize_status = 'macaroons';
  } else {
    customize_status = 'customize';
  }
  return (
    <div className="bg-black text-white px-10 -z-10 pt-2">
      {/* Festival Pastries Start Here*/}
      <div className="container-fluid px-6 md:m-0">
        {FestivalHampers !== undefined && FestivalHampers && FestivalHampers.length > 0 && (
          <h4 className="text-center pb-8 mb-5 text-xl md:text-3xl uppercase tracking-wider">
            <div className="striking-title items-center grid grid-cols-12 text-white">
              <hr className="col-span-12 lg:col-span-3" />
              <h1 className="col-span-12 lg:col-span-6 text-center">
                {dic.t('Margaux-Festive Hampers')}
              </h1>
              <hr className="col-span-12 lg:col-span-3" />
            </div>
          </h4>
        )}
        <div className="grid grid-cols-1 sm:grid-cols-1 mb-10">
          <Slider {...festivalHamperSettings}>
            {FestivalHampers !== undefined && FestivalHampers
              ? FestivalHampers.map((items, key) => {
                  const exists = cartItems.filter((currentcart) => {
                    return currentcart?.ItemDetails?.id === items.id;
                  });
                  var counts = 0;
                  exists.map((itemcheck) => {
                    itemcheck.cartaddedservice.map((serviceitem) => {
                      return (counts = counts + serviceitem?.quantity?.value);
                    });
                  });
                  const pricelist = items.fields.Services;
                  const findlowestprice = Math.min(
                    ...pricelist.map((item) => item.fields.Price.value)
                  );
                  const checkCustomize =
                    items?.fields?.Services[0]?.fields['Service To Persons']?.fields?.Code?.value;
                  const description = items?.fields?.Description?.fields?.Description?.value;
                  const descriptionplain = description.slice(0, 120) + '...';
                  const images = items?.fields?.['Pastry Images'][0]?.fields?.Image?.value?.src;
                  const allimages = items?.fields?.['Pastry Images'];
                  const addons = items?.fields?.Addons;
                  const cakename = items?.fields['Pastry Name']?.fields?.Title?.value;
                  return (
                    <div className="flex justify-center md:px-6 px-0" key={key}>
                      <div className="flex justify-center">
                        <div className="max-w-full bg-white relative overflow-hidden rounded-lg shadow-md">
                          {/* <div className="absolute left-0 top-0 h-16 w-16 z-[9]">
                            <div className="absolute transform -rotate-45 bg-gray-500 text-center text-white font-semibold py-1 left-[-35px] top-[33px] w-[170px]">
                              Festival
                            </div>
                          </div> */}
                          {/* Currency Ribbon */}
                          {/* <div className="relative z-[9]">
                            <div className="absolute bottom-0 right-0 ...">
                              <div className="absolute bg-gray-500 text-center rounded-l-md text-white font-semibold py-1 left-[-80px] top-[200px] w-[80px]">
                                {helpers.currency} {findlowestprice}
                              </div>
                            </div>
                          </div> */}
                          <img
                            className="w-screen object-cover h-60 hover:scale-125 ease-in duration-500  xl:h-64 2xl:h-72 rounded-t-lg"
                            src={images}
                            alt="product image"
                          />
                          {/* <img
                            className="w-screen object-cover h-60 hover:scale-125 ease-in duration-500  xl:h-64 2xl:h-72 rounded-t-lg"
                            src={images}
                            alt="product image"
                            loading="lazy"
                          /> */}
                          <div className="px-5 py-4 border-t-2 border-gray-500 relative z-[9] bg-white">
                            <h5 className="sm:text-md text-sm font-semibold tracking-widest text-gray-900">
                              {cakename}
                            </h5>
                            {/* Cake Description  */}
                            <div className="text-gray-700 text-[0.68rem] py-2 ">
                              <div
                                style={{ display: 'inline' }}
                                dangerouslySetInnerHTML={{
                                  __html: descriptionplain,
                                }}
                              />
                              <span
                                onClick={() =>
                                  ViewMoreUpdate(
                                    description,
                                    allimages,
                                    cakename,
                                    items,
                                    addons,
                                    checkCustomize === '' ? 'customize' : 'macaroons',
                                    exists,
                                    counts,
                                    findlowestprice
                                  )
                                }
                                className="text-gray-700 font-bold cursor-pointer underline underline-offset-1 hover:text-sky-800"
                              >
                                See More
                              </span>
                            </div>
                            <div>
                              <div className="grid grid-cols-2">
                                <div className="flex relative cursor-pointer">
                                  <div className="relative font-bold text-gray-900 pr-2 py-2 sm:pr-2 lg:pr-5 sm:py-2.5">
                                    {helpers.currency} {findlowestprice}
                                  </div>
                                </div>
                                <div className="flex relative	cursor-pointer text-sm sm:text-lg justify-end">
                                  {exists &&
                                  exists[0] &&
                                  exists[0].cartaddedservice &&
                                  exists[0].cartaddedservice.length > 0 ? (
                                    <div className="grid grid-cols-3 addtocartbox bg-gray-700 px-1 py-1 rounded-md  h-[36px]">
                                      <div
                                        className="flex relative top-1	cursor-pointer"
                                        onClick={SorryCartStatusUpdate}
                                      >
                                        <Decrement />
                                      </div>
                                      <div className="flex text-white relative top-1 sm:top-0 px-1 justify-center">
                                        {counts}
                                      </div>
                                      <div
                                        className="flex relative top-[0.2em] cursor-pointer"
                                        onClick={() =>
                                          modalUpdateStatus(
                                            items,
                                            addons,
                                            checkCustomize === '' ? 'customize' : 'macaroons'
                                          )
                                        }
                                      >
                                        <Increment />
                                      </div>
                                    </div>
                                  ) : (
                                    <div className="grid grid-cols-1 justify-items-center h-[36px]">
                                      <Link
                                        onClick={() =>
                                          modalUpdateStatus(
                                            items,
                                            addons,
                                            checkCustomize === '' ? 'customize' : 'macaroons'
                                          )
                                        }
                                        className="text-white font-normal hover:bg-gray-700 bg-gray-600 uppercase rounded-lg text-xs ml-2 sm:ml-0 lg:text-sm px-2 py-2 sm:px-2 lg:px-5  sm:pt-2.5 text-center"
                                      >
                                        {dic.t('Margaux-Add To Cart')}
                                      </Link>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })
              : ''}
          </Slider>
        </div>
      </div>
      {/* Festival Pastries End Here*/}
      {/* Signature Box Start Here */}
      <div className="py-3 bg-black">
        <SignatureBox
          signatureBox={signatureBox}
          cartItems={cartItems}
          SorryCartStatusUpdate={SorryCartStatusUpdate}
          modalUpdateStatus={modalUpdateStatus}
          Description={Description}
        />
      </div>
      {/* Signature Box End Here */}
      {/* Top Recommened Pastries Start Here*/}
      <div className="container-fluid px-6 md:m-0">
        <h4 className="text-center pb-8 mb-5 text-xl md:text-3xl uppercase tracking-wider">
          <div className="striking-title items-center grid grid-cols-12 text-white">
            <hr className="col-span-12 lg:col-span-4" />
            <h1 className="col-span-12 lg:col-span-4 text-center">
              {dic.t('Margaux-Our Favorites')}
            </h1>
            <hr className="col-span-12 lg:col-span-4" />
          </div>
        </h4>
        <div className="grid grid-cols-1 sm:grid-cols-1">
          <Slider {...topRecommendSettings}>
            {toprecommend !== undefined && toprecommend
              ? toprecommend.map((items, key) => {
                  const exists = cartItems.filter((currentcart) => {
                    return currentcart?.ItemDetails?.id === items.id;
                  });
                  var counts = 0;
                  exists.map((itemcheck) => {
                    itemcheck.cartaddedservice.map((serviceitem) => {
                      return (counts = counts + serviceitem?.quantity?.value);
                    });
                  });
                  const pricelist = items.fields.Services;
                  const findlowestprice = Math.min(
                    ...pricelist.map((item) => item.fields.Price.value)
                  );
                  const checkCustomize =
                    items?.fields?.Services[0]?.fields['Service To Persons']?.fields?.Code?.value;
                  const description = items?.fields?.Description?.fields?.Description?.value || '';
                  const descriptionplain = description.slice(0, 180) + '...';
                  const images = items?.fields?.['Pastry Images'][0]?.fields?.Image?.value?.src;
                  const cutimage = items?.fields?.['Pastry Images'][1]?.fields?.Image?.value?.src;
                  const allimages = items?.fields?.['Pastry Images'];
                  const addons = items?.fields?.Addons;
                  const cakename = items?.fields['Pastry Name']?.fields?.Title?.value;
                  const cakeCategory =
                    items &&
                    items.fields &&
                    items.fields['Pastry Category'] &&
                    items.fields['Pastry Category'].fields &&
                    items.fields['Pastry Category'] &&
                    items.fields['Pastry Category'].fields.Title &&
                    items.fields['Pastry Category'].fields.Title.value;
                  console.log(cakeCategory, 'cakeFlavors');
                  return (
                    <div
                      className="flex justify-center md:px-6 px-0"
                      key={key}
                      category={cakeCategory}
                    >
                      <div className="flex justify-center" category={cakeCategory}>
                        <div className="max-w-full bg-white relative overflow-hidden rounded-lg shadow-md">
                          <div className="absolute left-0 top-0 h-16 w-16 z-[9]">
                            <div className="absolute transform -rotate-45 bg-gray-500 text-center text-white font-semibold py-1 left-[-35px] top-[33px] w-[170px]">
                              Recommended
                            </div>
                          </div>
                          {/* Currency Ribbon */}
                          {/* <div className="relative z-[9]">
                            <div className="absolute bottom-0 right-0 ...">
                              <div className="absolute bg-gray-500 text-center rounded-l-md text-white font-semibold py-1 left-[-80px] top-[200px] w-[80px]">
                                {helpers.currency} {findlowestprice}
                              </div>
                            </div>
                          </div> */}
                          <img
                            className={`w-screen object-cover h-60 ${
                              allimages.length > 1 ? '' : 'hover:scale-125'
                            }   ease-in duration-500  xl:h-64 2xl:h-72 rounded-t-lg`}
                            src={images}
                            alt="product image"
                            onMouseOver={(e) =>
                              (e.currentTarget.src = allimages.length > 1 ? cutimage : images)
                            }
                            onMouseOut={(e) => (e.currentTarget.src = images)}
                          />
                          {/* <img
                            className="w-screen object-cover h-60 hover:scale-125 ease-in duration-500  xl:h-64 2xl:h-72 rounded-t-lg"
                            src={images}
                            alt="product image"
                            loading="lazy"
                          /> */}
                          <div className="px-5 py-4 border-t-2 border-gray-500 relative z-[9] bg-white">
                            <h5 className="sm:text-xl text-sm font-semibold tracking-widest text-gray-900">
                              {cakename}
                            </h5>
                            {/* Cake Description  */}
                            <div className="text-gray-700 text-[0.68rem] py-2 ">
                              <div
                                style={{ display: 'inline' }}
                                dangerouslySetInnerHTML={{
                                  __html: descriptionplain,
                                }}
                              />
                              <span
                                onClick={() =>
                                  ViewMoreUpdate(
                                    description,
                                    allimages,
                                    cakename,
                                    items,
                                    addons,
                                    checkCustomize === '' ? 'customize' : 'macaroons',
                                    exists,
                                    counts,
                                    findlowestprice
                                  )
                                }
                                className="text-gray-700 font-bold cursor-pointer underline underline-offset-1 hover:text-sky-800"
                              >
                                See More
                              </span>
                            </div>
                            <div>
                              <div className="grid grid-cols-2">
                                <div className="flex relative cursor-pointer">
                                  <div className="relative font-bold text-gray-900 pr-2 py-2 sm:pr-2 lg:pr-5 sm:py-2.5">
                                    {helpers.currency} {findlowestprice}
                                  </div>
                                </div>
                                <div className="flex relative	cursor-pointer text-sm sm:text-lg justify-end">
                                  {exists &&
                                  exists[0] &&
                                  exists[0].cartaddedservice &&
                                  exists[0].cartaddedservice.length > 0 ? (
                                    <div className="grid grid-cols-3 addtocartbox bg-gray-700 px-1 py-1 rounded-md  h-[36px]">
                                      <div
                                        className="flex relative top-1	cursor-pointer"
                                        onClick={SorryCartStatusUpdate}
                                      >
                                        <Decrement />
                                      </div>
                                      <div className="flex text-white relative top-1 sm:top-0 px-1 justify-center">
                                        {counts}
                                      </div>
                                      <div
                                        className="flex relative top-[0.2em] cursor-pointer"
                                        onClick={() =>
                                          modalUpdateStatus(
                                            items,
                                            addons,
                                            checkCustomize === '' ? 'customize' : 'macaroons'
                                          )
                                        }
                                      >
                                        <Increment />
                                      </div>
                                    </div>
                                  ) : (
                                    <div className="grid grid-cols-1 justify-items-center h-[36px]">
                                      <Link
                                        onClick={() =>
                                          modalUpdateStatus(
                                            items,
                                            addons,
                                            checkCustomize === '' ? 'customize' : 'macaroons'
                                          )
                                        }
                                        className="text-white font-normal hover:bg-gray-700 bg-gray-600 uppercase rounded-lg text-xs ml-2 sm:ml-0 lg:text-sm px-2 py-2 sm:px-2 lg:px-5  sm:pt-2.5 text-center"
                                      >
                                        {dic.t('Margaux-Add To Cart')}
                                      </Link>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })
              : ''}
          </Slider>
        </div>
      </div>
      {/* Top Recommened Pastries End Here*/}
      {/* Top Trending Pastries Start Here*/}
      <div className="container-fluid px-6 pb-4 sm:pt-8 md:m-0">
        <h4 className="text-center py-8 mb-5 text-xl md:text-3xl uppercase tracking-wider">
          <div className="striking-title items-center grid grid-cols-12 text-white">
            <hr className="col-span-12 lg:col-span-3" />
            <h1 className="col-span-12 lg:col-span-6 text-center">
              {dic.t('Margaux-Discover Our Best Sellers')}
            </h1>
            <hr className="col-span-12 lg:col-span-3" />
          </div>
        </h4>
        <div className="grid grid-cols-1 sm:grid-cols-1">
          <Slider {...topTrendingSettings}>
            {toptrending !== undefined && toptrending
              ? toptrending.map((items, key) => {
                  const exists = cartItems.filter((currentcart) => {
                    return currentcart?.ItemDetails?.id === items.id;
                  });
                  var counts = 0;
                  exists.map((itemcheck) => {
                    itemcheck.cartaddedservice.map((serviceitem) => {
                      return (counts = counts + serviceitem?.quantity?.value);
                    });
                  });
                  const checkCustomize =
                    items?.fields?.Services[0]?.fields['Service To Persons']?.fields?.Code?.value;
                  const pricelist = items.fields.Services;
                  const findlowestprice = Math.min(
                    ...pricelist.map((item) => item.fields.Price.value)
                  );
                  const description = items?.fields?.Description?.fields?.Description?.value;
                  const descriptionplain = description.slice(0, 180) + '...';
                  const images = items?.fields?.['Pastry Images'][0]?.fields?.Image?.value?.src;
                  const cutimage = items?.fields?.['Pastry Images'][1]?.fields?.Image?.value?.src;
                  const allimages = items?.fields?.['Pastry Images'];
                  const addons = items?.fields?.Addons;
                  const cakename = items?.fields['Pastry Name']?.fields?.Title?.value;
                  const cakeCategory =
                    items &&
                    items.fields &&
                    items.fields['Pastry Category'] &&
                    items.fields['Pastry Category'].fields &&
                    items.fields['Pastry Category'] &&
                    items.fields['Pastry Category'].fields.Title &&
                    items.fields['Pastry Category'].fields.Title.value;
                  return (
                    <div
                      className="flex justify-center md:px-6 px-0"
                      key={key}
                      category={cakeCategory}
                    >
                      <div className="flex justify-center" category={cakeCategory}>
                        <div className="max-w-full bg-white relative overflow-hidden rounded-lg shadow-md">
                          <img
                            className={`w-screen object-cover h-60 ${
                              allimages.length > 1 ? '' : 'hover:scale-125'
                            } ease-in duration-500  xl:h-64 2xl:h-72 rounded-t-lg`}
                            src={images}
                            alt="product image"
                            onMouseOver={(e) =>
                              (e.currentTarget.src = allimages.length > 1 ? cutimage : images)
                            }
                            onMouseOut={(e) => (e.currentTarget.src = images)}
                          />
                          {/* <img
                            className="w-screen object-cover h-60 hover:scale-125 ease-in duration-500  xl:h-64 2xl:h-72 rounded-t-lg"
                            src={images}
                            alt="product image"
                            loading="lazy"
                          /> */}
                          <div className="px-5 py-4 border-t-2 border-gray-500 relative z-[9] bg-white	">
                            <h5 className="sm:text-xl text-sm font-semibold tracking-widest text-gray-900">
                              {cakename}
                            </h5>
                            <p className="text-gray-700 text-[0.68rem] py-2 font-didotitalic">
                              <div
                                style={{ display: 'inline' }}
                                dangerouslySetInnerHTML={{
                                  __html: descriptionplain,
                                }}
                              />
                              <span
                                onClick={() =>
                                  ViewMoreUpdate(
                                    description,
                                    allimages,
                                    cakename,
                                    items,
                                    addons,
                                    checkCustomize === '' ? 'customize' : 'signature',
                                    exists,
                                    counts,
                                    findlowestprice
                                  )
                                }
                                className="text-gray-700 font-bold cursor-pointer underline underline-offset-1 hover:text-sky-800"
                              >
                                See More
                              </span>
                            </p>
                            <div>
                              <div className="grid grid-cols-2">
                                <div className="flex relative cursor-pointer">
                                  <div className="relative font-bold text-gray-900 pr-2 py-2 sm:pr-2 lg:pr-5 sm:py-2.5">
                                    {helpers.currency} {findlowestprice}
                                  </div>
                                </div>
                                <div className="flex relative	cursor-pointer text-sm sm:text-lg  justify-end">
                                  {exists &&
                                  exists[0] &&
                                  exists[0].cartaddedservice &&
                                  exists[0].cartaddedservice.length > 0 ? (
                                    <div className="grid grid-cols-3 addtocartbox bg-gray-700 px-1 py-1 rounded-md  h-[36px]">
                                      <div
                                        className="flex relative top-1	cursor-pointer"
                                        onClick={SorryCartStatusUpdate}
                                      >
                                        <Decrement />
                                      </div>
                                      <div className="flex text-white relative top-1 sm:top-0 px-1 justify-center">
                                        {counts}
                                      </div>
                                      <div
                                        className="flex relative top-[0.2em] cursor-pointer"
                                        onClick={() =>
                                          modalUpdateStatus(
                                            items,
                                            addons,
                                            checkCustomize === '' ? 'customize' : 'signature'
                                          )
                                        }
                                      >
                                        <Increment />
                                      </div>
                                    </div>
                                  ) : (
                                    <div className="grid grid-cols-1 justify-items-center  h-[36px]">
                                      <Link
                                        onClick={() =>
                                          modalUpdateStatus(
                                            items,
                                            addons,
                                            checkCustomize === '' ? 'customize' : 'signature'
                                          )
                                        }
                                        className="text-white font-normal hover:bg-gray-700 bg-gray-600 uppercase rounded-lg text-xs ml-2 sm:ml-0 lg:text-sm px-2 py-2 sm:px-2 lg:px-5  sm:pt-2.5 text-center"
                                      >
                                        {dic.t('Margaux-Add To Cart')}
                                      </Link>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })
              : ''}
          </Slider>
        </div>
      </div>
      {/* Top Trending Pastries End Here*/}
      {/* Product Service Start */}
      {serviceModal === true && (
        <>
          <ProductService
            modalStatus={serviceModal}
            modalUpdateStatus={modalUpdateStatus}
            productAddons={productAddons}
            servicelist={serviceUpdate}
            onCartFunction={onCartFunction}
            closeModal={closeModal}
            type={servings}
          />
        </>
      )}
      {/* Customize Service End */}
      {customizeModal === true && (
        <>
          <CustomizeService
            pastryName={pastryName}
            customizeType={customizeType}
            closeModalCustomize={closeModalCustomize}
            modalStatus={customizeModal}
            modalUpdateStatus={modalUpdateStatus}
            productAddons={productAddons}
            servicelist={serviceUpdate}
            onCartFunction={onCartFunction}
            closeModal={closeModal}
            type={servings}
          />
        </>
      )}
      {/* Product Service End */}
      {/* Sorry Cart Start */}
      {getSorryStatus === true && (
        <SorryCart SorryCartStatusUpdate={SorryCartStatusUpdate} checkModal={getSorryStatus} />
      )}
      {/* Sorry Cart End */}
      {/* View More Start */}
      {getViewMore === true && (
        <ViewMore
          SorryCartStatusUpdate={SorryCartStatusUpdate}
          modalUpdateStatus={modalUpdateStatus}
          getDescription={getDescription}
          ViewMoreUpdate={ViewMoreUpdate}
          getViewMore={getViewMore}
        />
      )}
      {/* View More End */}
    </div>
  );
}

export default TopRecommendedCakes;
