import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { onCartAction } from '../../actions/cart/addtocart.action';
import ChefInfo from '../ChefInfo';
import SignatureBox from '../SignatureBox';
import TopRecommendedCakes from './TopRecommendedCakes';
function RecommendedPastries(props) {
  const toprecommend = props?.fields?.['Top Recommended Pastries'];
  const toptrending = props?.fields?.['Top Trending Pastries'];
  const signatureBox = props?.fields?.['Signature Boxes'];
  const pastryName = props && props?.fields?.['Pastry Names'];
  const FestivalHampers = props && props?.fields?.['Festive Hampers'];
  const Description = props && props?.fields?.Description?.value;
  const servings = props && props?.fields?.Servings?.value;
  console.log(toprecommend, 'toprecommend');
  return (
    <Fragment>
      <TopRecommendedCakes
        toprecommend={toprecommend}
        toptrending={toptrending}
        signatureBox={signatureBox}
        FestivalHampers={FestivalHampers}
        pastryName={pastryName}
        Description={Description}
        servings={servings}
      />
    </Fragment>
  );
}

export default RecommendedPastries;
